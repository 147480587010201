import React, {useState,useEffect} from "react";
import {getCurrencyLocal, getToken} from "../axios-client";
import {toast} from "react-toastify";
import TranslateComponent, {translateText} from "./TranslateComponent";
import {useSelector} from "react-redux";
import PopupForLogin from "./PopupForLogin";
import PopupComponent from "./PaymentSteps/PopupCopmponent";

const DonationCardInSlider = ({id,className=null})=>{
    const translations = useSelector((state) => state.Storetranslate.translations);
    const donationAmounts = [50, 100, 200, 400];
    const [donation, setDonation] = useState('');
    const [activeDonation, setActiveDonation] = useState(null);
    const [price ,setPrice]=useState('');
    const [showPopup , setShowPopup] = useState(false);

    const handleDonation = (value) => {
        setDonation(value)
        setActiveDonation(value);
        setPrice(value);
        setShowPopup(true);
         // Update localStorage after setting states
  localStorage.setItem("price", value);

  // Dispatch custom event for `PopupComponent`
  const event = new Event("priceChanged");
  window.dispatchEvent(event);
    }
    const handelChangeDonationInput=(e)=>{
        const {value}=e.target;
        setDonation(value)
        setPrice(value);
        setShowPopup(true);
    }

    useEffect(() => {
        if (price || donation) {
          localStorage.setItem("price", price || donation);
        }
      }, [price,donation]);

    const [openPopup, setOpenPopup] = useState(false);
    const handleRemovePopUp = () => setOpenPopup(false);
    const[requestType,setRequestType]=useState(false)

    const forPay=(pricePay)=>{
        // eslint-disable-next-line no-mixed-operators
        if(getToken() && getToken()!=='' || getToken() !==null){
            showPopup&&(  document.getElementById('my_modal_4_box').showModal() )
            if(!pricePay){
                toast.error(translateText('Please enter the donation amount',translations));
            }
        }else{
            setOpenPopup(true);
        }
    }
    window.addEventListener('removeForPayDirect',()=>{
        setPrice('');
        setDonation('')
    }) 
    return (
        <>
            <div className={`container ${className} w-full col-start-1 col-end-3  col-span-1 max-w-7xl mx-auto px-2   row-start-2  md:col-start-2 `}>
                <div
                    className="relative top-0 w-full p-[40px] grow items-start md:max-w-96 text-sm font-medium bg-white rounded-2xl shadow-lg md:top-20 ltr:lg:ml-auto ltr:lg:mr-[unset] rtl:lg:mr-auto rtl:lg:ml-[unset] xl:ml-0 ltr:lg:top-20">

                    <div className="text-3xl font-bold text-start text-zinc-900">
                    <TranslateComponent word={'Quick donation'}/>
                    </div>
                    <div className="mt-6 text-slate-700"><TranslateComponent word={'Choose the amount'}/> </div>
                    <div className="flex gap-2.5 self-stretch mt-4 text-sm font-bold text-start whitespace-nowrap text-neutral-900">
                        {donationAmounts.map((amount, index) => (
                            <button
                                key={index}
                                onClick={() => handleDonation(amount)}
                                className={`justify-center px-${amount === '$' ? '6' : '3.5'} py-2.5 first:px-4 ${amount === activeDonation ? 'text-white bg-blue-900' : 'bg-white'
                                } rounded-lg border-solid aspect-[1.3] border-[0.8px] border-[color:var(--Primary-color,#274182)]`}
                            >
                                <span className="relative z-10">{amount}</span>

                            </button>
                        ))}
                    </div>
                    <div className="mt-6 text-slate-700">
                    <TranslateComponent word={'Enter the donation amount'}/></div>

                    <div
                        className="flex gap-2 justify-between self-stretch px-3.5 py-2.5 mt-1.5 text-sm text-right bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                        <input
                            className="text-black outline-none px-2 w-full"
                            value={donation}
                            type="number"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            placeholder="00"
                            min={50}
                            max={100000}
                            onChange={handelChangeDonationInput}
                        />
                        <span>{getCurrencyLocal() && getCurrencyLocal().code}</span>
                    </div>
                    <div className={'flex'}>
                        <div
                            onClick={()=>{
                                forPay(price)
                            }}
                            role="button"
                            className="justify-center p-3 mt-6 text-sm font-bold leading-5 bg-amber-300 rounded-lg shadow-sm text-neutral-900">
                            <TranslateComponent word={'Donate_now'}/>
                        </div>
                    </div>

                    <div className="mt-6 text-sm leading-6 text-start text-zinc-600">
                        * <TranslateComponent word={'This donation will be spent on the most needy projects'}/>
                    </div>
                </div>
            </div>
            {!getToken() &&(
                <PopupForLogin
                    type={'box'}
                    openPopUp={openPopup}
                    closePopUp={handleRemovePopUp}
                    requestType={requestType}
                    setRequestType={setRequestType}
                    id={id}
                    adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
                    totalPrice={price}
                />
            )}
            {showPopup&& getToken() &&(
                <PopupComponent type={'box'} setShowPopup={setShowPopup} projectId={id} price={price} symbol={getCurrencyLocal() && getCurrencyLocal().code} handelChangePrice={handelChangeDonationInput}/>
            )}
        </>
    )
}
export default DonationCardInSlider;