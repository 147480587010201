import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCurrencyLocal, getToken } from "../axios-client";
import { useDispatch, useSelector } from "react-redux";
import Donuations from "./Donuations";
import DonationNow from "./DonationNow";
import WhayDonation from "./WhayDonation";
import TranslateComponent, { translateText } from "./TranslateComponent";
import { Arrow, FilterIcon } from "../Images/svgs";
import PopupFilter from "./PopusFolder/PopupFilter";
import { useParams } from "react-router-dom";
import Navbar from "./Layouts/Navbar";
import SecondNavbar from "./Layouts/SecondNavbar";
import Breadcrumbs from "./Partials/Breadcrumbs";
import Footer from "./Layouts/Footer";
import FooterNav from "./Layouts/FooterNav";
import { toast } from "react-toastify";
import PopupForLogin from "./PopupForLogin";
import PopupComponent from "./PaymentSteps/PopupCopmponent";
import { useRef } from "react";
import NavScroll from "./Layouts/NavScroll";
import CustomLink from "../CustomLink";
import FixedProjectCard from "./Projects/FixedProjectCard";
import { fetchProjects } from "../Managment/CategoryProjects";
import DonationCardInSlider from "./DonationCardInSlider";

const FixedProjects = () => {
  const navScrollingRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [filterd, setFilterd] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const [showPopup, setShowPopup] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [requestType, setRequestType] = useState(false);
  const [price, setPrice] = useState(null);
  const handleRemovePopUp = () => setOpenPopup(false);
  const { data } = useSelector((state) => state.StoreCategoryProjects);
  const { pageData } = useSelector((state) => state.StoreCategoryProjects);
  useEffect(() => {
    if (slug) {
      sessionStorage.setItem("fixedSlug", slug);
      dispatch(fetchProjects(slug));
    }
  }, [slug]);

  const toggleShowList = () => {
    setShowList(!showList);
  };
  useEffect(() => {
    if (data && data.length > 0) {
      const maxValueData = data.reduce((max, project) => {
        return project.project_value > max ? project.project_value : max;
      }, -Infinity);
      const startValueData = maxValueData * 0.25;
      const endValueData = maxValueData * 0.75;
      setMaxValue(maxValueData);
      setStartValue(startValueData);
      setEndValue(endValueData);
    }
  }, [data, filterd]);

  const crumps = [
    { name: "Project Category", link: "projects" },
    { name: pageData?.name, link: "" },
  ];

  useEffect(() => {
    if (pageData.donationBox?.price !== undefined) {
      setPrice(pageData.donationBox?.price);
      localStorage.setItem("price", pageData.donationBox?.price);
      dispatchEvent(new Event("priceChanged"));
    }
  }, [price, pageData]);

  const forPay = (pricePay) => {
    // eslint-disable-next-line no-mixed-operators
    if ((getToken() && getToken() !== "") || getToken() !== null) {
      if (pricePay) {
        localStorage.setItem("price", pricePay);
        dispatchEvent(new Event("priceChanged"));
        setPrice(pricePay);
        showPopup && document.getElementById("my_modal_4_box").showModal();
      } else {
        toast.error(translateText("No donation amount !", translations));
      }
    } else {
      setOpenPopup(true);
    }
  };
  useEffect(() => {
    setShowPopup(true);
  }, [showPopup]);

  window.addEventListener("removeForPayDirect", () => {
    setShowPopup(false);
  });

  return (
    <>
      <div className={`relative h-full pb-[60px] md:pb-0 !bg-[#EAEFFB]`}>
        <div
          ref={navScrollingRef}
          className="stickyNav z-40 border-b bg-[#FFFFFF]   "
        >
          <Navbar />
          <SecondNavbar />
          <NavScroll headerRef={navScrollingRef} />
        </div>
        <Breadcrumbs allData={crumps} />

        {pageData && (
          <>
            <section className="relative min-h-[473px] w-full grid">
              <LazyLoadImage
                src={pageData?.sliderPhoto}
                alt=""
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div class="absolute inset-0 bg-gray-700 lg:opacity-0 opacity-60 lg:rounded-md"></div>
              <div className="z-10 w-full  container col-span-1  max-w-7xl mx-auto px-4 lg:px-0 ">
                <div className="ProjectCategoryHero grid-rows-[0px_200px] grid grid-cols-2 sm:p-5 gap-6 md:gap-0 ltr:md:gap-x-9 ">
                  <section className="col-span-1  mr-6 md:mr-0 gap-y-4 lg:row-start-3 items-start row-start-1 md:row-start-2 col-start-1 col-end-3  self-end md:col-end-1 lg:col-end-3  grid rtl:ml-auto ltr:mr-0 ltr:ml-6 ltr:col-end-3 ltr:md:col-end-2  lg:mt-auto lg:mb-auto ">
                    <div className="max-w-[600px]  max-md:mt-10">
                      <h2 className="text-2xl md:text-4xl font-bold text-white ">
                        {pageData?.sectionTitle}
                      </h2>
                      <p className="mt-4 text-lg lg:text-xl font-medium text-white ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pageData?.sectionDescription,
                          }}
                        />
                      </p>
                    </div>
                    <div className="flex flex-row  gap-8 w-fit self-baseline">
                      <CustomLink
                        to={"/projects"}
                        className="p-2 rounded-lg border font-bold border-white hover:bg-amber-300 hover:text-black hover:border-amber-300 text-white w-32 text-center ltr:w-fit ltr:h-fit"
                      >
                        <TranslateComponent word={"Browse projects"} />
                      </CustomLink>
                      <div
                        role={"button"}
                        onClick={() => forPay(pageData.donationBox?.price)}
                        className="p-2 rounded-lg border border-amber-300 bg-amber-300 font-bold text-black  hover:border-amber-500 hover:bg-amber-500 w-32 text-center"
                      >
                        <TranslateComponent word={"Donate now"} />
                      </div>
                    </div>
                  </section>
                  <DonationCardInSlider
                    id={pageData.donationBox?.box_id}
                    className={" mb-[-20px] lg:mb-[0] lg:mt-[unset]"}
                  />
                </div>
              </div>
            </section>
            <div className={"container max-w-7xl mx-auto"}>
              <div className="flex flex-col ">
                <div className={"mb-5 rtl:mr-4  ltr:mr-0 ltr:ml-4"}>
                  {/* ltr:mt-6 */}
                  <div className="self-start rtl:mt-16 ltr:mt-[70px]  text-3xl font-bold text-start text-zinc-900 max-md:mt-14 max-md:max-w-full ">
                    {pageData.second_section_title}
                  </div>

                  <div className="mt-5 mb-10 text-base tracking-wide leading-7 text-start text-zinc-800 max-md:max-w-full">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData.second_section_description,
                      }}
                    />
                  </div>
                </div>
                {data.length > 0 && (
                  <div className={"rtl:mr-4 mb-5 ltr:mr-0 ltr:ml-4"}>
                    <div className="flex justify-between mb-4 sm:mb-0">
                      <div className="text-3xl font-bold">
                        <TranslateComponent
                          word={"Donations in the field of education"}
                        />
                      </div>
                      {/* <button
                        onClick={toggleShowList}
                        className="flex-row gap-2 text-black border-2 h-fit font-medium md:font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center outline-none"
                      >
                        <FilterIcon />
                        <TranslateComponent word={"Filter New"} />
                        <Arrow />
                      </button> */}
                      {showList && (
                        <div className="relative">
                          <div className="fixed inset-0 z-50 flex items-center justify-center">
                            <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
                            <div className="absolute top-24">
                              <PopupFilter
                                startValue={startValue}
                                endValue={endValue}
                                maxValue={maxValue}
                                toggleShowList={toggleShowList}
                                setFilterd={setFilterd}
                                from={"ProjectCategory"}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-span-1 text-end flex-0">
                  <div
                    className={
                      "grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4  gap-5"
                    }
                  >
                    {data?.map((item, index) => {
                      return (
                        <FixedProjectCard
                          project={item}
                          key={`project_${index}`}
                        />
                      );
                    })}
                  </div>
                </div>
                <Donuations data={pageData.donors} />
                {/* <div className='mt-[60px]'></div> */}
                <DonationNow data={pageData?.donationBox} forPay={forPay} />

                <WhayDonation
                  title={pageData.third_section_title}
                  photo={pageData.thirdSectionPhoto}
                  description={pageData.third_section_description}
                />
              </div>
            </div>
          </>
        )}

        {!getToken() && (
          <PopupForLogin
            type={"box"}
            openPopUp={openPopup}
            closePopUp={handleRemovePopUp}
            requestType={requestType}
            setRequestType={setRequestType}
            id={pageData.donationBox?.box_id}
            adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
            totalPrice={pageData.donationBox?.price}
          />
        )}
        {showPopup && getToken() && (
          <PopupComponent
            type={"box"}
            subscription={false}
            setShowPopup={setShowPopup}
            projectId={pageData.donationBox?.box_id}
            price={pageData.donationBox?.price}
            symbol={getCurrencyLocal() && getCurrencyLocal().code}
          />
        )}
        <Footer />
        <FooterNav />
      </div>
    </>
  );
};

export default FixedProjects;
