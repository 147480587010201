import TranslateComponent from 'Components/TranslateComponent';
import { LeftICon } from 'Images/svgs';
import React from 'react'
import { useNavigate } from 'react-router-dom';

function BtnBack() {
    const navigate = useNavigate();
        const handleBack = () => {
            navigate(-1);
        };
  return (
    <button
                        onClick={handleBack}
                        className="flex text-start mt-10 items-center gap-2 text-base w-[50px] text-zinc-900  mb-4"
                    >
                        <div className="h-[20px] back_LeftICon">
                            <LeftICon/>
                        </div>
                        <div className="grow"><TranslateComponent word={'Back'} /></div>
    </button>
  )
}

export default BtnBack