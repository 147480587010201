import React from "react";
import Header from "./FixedProject/Header";
import DonationForm from "./FixedProject/DonationForm";
import ProjectInfo from "./FixedProject/ProjectInfo";
import Navbar from "../Layouts/Navbar";
import SecondNavbar from "../Layouts/SecondNavbar";
import NavScroll from "../Layouts/NavScroll";
import Breadcrumbs from "../Partials/Breadcrumbs";
import Footer from "../Layouts/Footer";
import FooterNav from "../Layouts/FooterNav";
import FixedProjectCard from "./FixedProjectCard";
import TranslateComponent from "../TranslateComponent";
import Logic from "./FixedProject/Logic";

function SingleFixedProject() {
    const {pagedata,translations,countries,setCountries,selectedFileShow,setSelectedFileShow,selectedFile,setSelectedFile,handleFileChange,
        type,setType,giftCheck,setGiftCheck,checkTextArea,setCheckTextArea,checkFile,setCheckFile,removeFile,name,setName,
        email,setEmail,selectedCountry,setSelectedCountry,selectedType,setSelectedType,desc,setDesc,data,
        navScrollingRef,handelFetchCountryByType,
    }=Logic();

    const crumps = [
        { 
            name: pagedata && pagedata?.fixedCategorySlug 
                ? pagedata.fixedCategorySlug
                    .replace(/-/g, ' ')       // Replace hyphens with spaces
                    .replace(/\d+/g, '')       // Remove all numbers
                    .trim()
                : pagedata?.fixedCategorySlug || '', 
            link: `MainProject/${pagedata?.fixedCategorySlug}` 
        },
        { 
            name: pagedata?.name || '', 
            link: '' 
        }
    ];
    return (
        <div className={``}>
            <div ref={navScrollingRef} className='stickyNav z-40 border-b bg-[#FFFFFF]'>
                <Navbar/>
                <SecondNavbar/>
                <NavScroll headerRef={navScrollingRef} />
            </div>
            <Breadcrumbs allData={crumps}/>
            <main className="">
                <section className="relative min-h-[473px] w-full flex">
                    <div className="">
                        <img
                            loading="lazy"
                            src={pagedata?.photo}
                            alt="img"
                            className="absolute object-cover inset-0 size-full "
                        />
                        <div class="absolute inset-0 bg-gray-700 lg:opacity-0 opacity-60 lg:rounded-md"></div>
                    </div>
                    <div className="container max-w-7xl mx-auto ">

                        <div
                            className="ProjectCategoryHero lg:mt-[10rem] md:flex flex-cols-3 sm:p-5 gap-6 md:gap-0 ltr:md:gap-x-9">
                            <div className="flex-1">
                                <Header
                                    title={pagedata?.name}
                                    description={pagedata?.shortDescription}
                                />
                                <ProjectInfo
                                    description={pagedata?.description} className={'hidden lg:block'}
                                />
                            </div>
                            <div className="container mb-[-20px] lg:mb-[0] lg:mt-[unset] w-full col-start-1 col-end-3 flex-1 max-w-7xl mx-auto px-2   row-start-2  md:col-start-2 ">
                                <DonationForm
                                    title={pagedata?.secondName}
                                    translations={translations}
                                    countries={countries}
                                    setCountries={setCountries}
                                    types={pagedata?.typeSubProjects}
                                    selectedFileShow={selectedFileShow}
                                    setSelectedFileShow={setSelectedFileShow}
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    handleFileChange={handleFileChange}
                                    type={type}
                                    setType={setType}
                                    giftCheck={giftCheck}
                                    setGiftCheck={setGiftCheck}
                                    checkTextArea={checkTextArea}
                                    setCheckTextArea={setCheckTextArea}
                                    checkFile={checkFile}
                                    setCheckFile={setCheckFile}
                                    removeFile={removeFile}
                                    name={name}
                                    setName={setName}
                                    email={email}
                                    setEmail={setEmail}
                                    selectedCountry={selectedCountry}
                                    setSelectedCountry={setSelectedCountry}
                                    selectedType={selectedType}
                                    setSelectedType={setSelectedType}
                                    desc={desc}
                                    setDesc={setDesc}
                                    handelFetchCountryByType={handelFetchCountryByType}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <ProjectInfo
                    description={pagedata?.description} className={'lg:hidden'}
                />

                <div
                    className={`mx-auto container max-w-7xl  p-2`}>
                    <h2 className=" mt-4 mb-2 ml-4 text-3xl font-bold tracking-wide leading-none text-center text-black">
                        <TranslateComponent word={'Projects for'}/> {pagedata?.name}
                    </h2>
                    <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5'}>
                        {(data?.map((item, index) => {
                            return (
                                <FixedProjectCard project={item} key={`project_${index}`}/>
                            )
                        }))}
                    </div>
                </div>
            </main>
            <Footer/>
            <FooterNav/>
        </div>
    );
}

export default SingleFixedProject;