import React, { useEffect, useRef, useState } from "react";
import "../css/custom.css";
import axiosClient, {
  getCatrs,
  getCurrencyLocal,
  getLocalLang,
  getToken,
  getUrl,
  Logout,
} from "../axios-client";
import TranslateComponent, { translateText } from "./TranslateComponent";
import { useSelector } from "react-redux";
import { Arrow, CloseIcon, SaudiFlag } from "../Images/svgs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PopupComponent from "./PaymentSteps/PopupCopmponent";
import PayAsAuth from "../Services/PayAsAuth";
import PopupForm from "./Adahy/PopupForm";
import { useNavigate } from "react-router-dom";

const PopupForLogin = ({
  ramadan,
  ramadanCountry,
  setRequestType,
  type,
  openPopUp,
  closePopUp,
  id,
  totalPrice,
  file,
}) => {
  const DataAll = useSelector((state) => state.StoreCountries.countries);
  const translations = useSelector(
    (state) => state.Storetranslate.translations
  );
  const [showPopupPay, setShowPopupPay] = useState(false);
  useEffect(() => {
    if (type !== "gift") {
      showPopupPay && document.getElementById(`my_modal_4_${type}`).showModal();
    } else {
      setOpenPopupGift(true);
    }
  }, [showPopupPay]);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCloseModal = () => {
    closePopUp();
    setRequestType(false);
    dispatchEvent(new Event("removeForPayDirect"));
    sessionStorage.removeItem("AsAuth");
    setInputs({
      name: "",
      email: "",
      phone: "",
    });
    Logout();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    // Add event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleCloseModal]);

  window.addEventListener("removeForPayDirect", () => {
    setRequestType(false);
    localStorage.removeItem("targetsDetails");

    closePopUp();
    setInputs({
      name: "",
      email: "",
      phone: "",
    });
  });

  //this Code For Gift Type ...Start...
  const [openPopupGift, setOpenPopupGift] = useState(false);
  const handleRemovePopUpGift = () => setOpenPopupGift(false);
  const [feedGift, setFeedGift] = useState([
    { name: "", value: "", karat: "24" },
  ]);
  const handleAddMoreInputsGift = () => {
    setFeedGift([...feedGift, { name: "", value: "", karat: "24" }]);
  };
  const handleRemoveMoreInputsGift = (index) => {
    const newFeed = feedGift.filter((_, i) => i !== index);
    setFeedGift(newFeed);
  };
  const [requestTypeGift, setRequestTypeGift] = useState(false);
  const handleInputChangeGift = (index, field, value) => {
    const newFeed = [...feedGift];
    newFeed[index][field] = value;
    setFeedGift(newFeed);
  };
  //this Code For Gift Type ...Finish...

  // this Codes For Country Code for Phone ...Start...
  const countryWrapperRef = useRef(null);
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    num_code: "+966",
    image: `${getUrl()}/assets/flags/ar.svg`,
    id: null,
  });
  const toggleCountryDropdown = () => setIsOpenCountry(!isOpenCountry);
  const [countries, setCountries] = useState([]);
  const [searchQueryCountry, setSearchQueryCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);
  const handleCountrySearch = (e) => {
    const query = e.target.value;
    setSearchQueryCountry(query);
    const filtered = countries.filter((country) =>
      country.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCountries(filtered);
  };
  const handleCountrySelection = async (country) => {
    setSelectedCountry(country);
    setIsOpenCountry(false);
    setSearchQueryCountry("");
    setFilteredCountries(countries);
  };
  const ClientData = useSelector((state) => state.StoreVisits.data);
  const getCountries = async () => {
    try {
      const data = DataAll;
      setCountries(data);
      setFilteredCountries(data);
      const userCountry = data.filter(
        (item, index) => item.iso === ClientData.country
      );
      setSelectedCountry(
        userCountry[0] ||
          data[0] || {
            name: "No countries",
            num_code: "+966",
            image: `${getUrl()}/assets/flags/ar.svg`,
            id: null,
          }
      );
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    getCountries();
  }, [DataAll, ClientData]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        countryWrapperRef.current &&
        !countryWrapperRef.current.contains(event.target)
      ) {
        setIsOpenCountry(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  // this Codes For Country Code for Phone ...Finish...

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const handelSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    axiosClient
      .post("payment/unauthenticated", {
        name: inputs.name,
        email: inputs.email,
        phone: inputs.phone,
        country: ClientData.country,
        ramadanCountry: ramadanCountry,
        isCart: type === "cart" ? 1 : 0,
        carts: type === "cart" ? getCatrs() && getCatrs() : [],
      })
      .then((res) => {
        if (res.data.status === 201) {
          sessionStorage.setItem("AsAuth", 1);
          PayAsAuth.ForRedirect(res.data.data.token, res.data.data.user);

          if (type === "cart") {
            sessionStorage.setItem("CartPayGuest", 1);
            navigate(`/${getLocalLang()?.code}/payment`);
          } else {
            setShowPopupPay(true);
            if (type === "gift") {
              setOpenPopupGift(true);
            }
          }
          setLoader(false);
          document.getElementById("GuestModelContainer").hidden = true;
          dispatchEvent(new Event("payAsAuth"));
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  if (!openPopUp) return null;

  return (
    <div
      id="ModelContainer"
      className="fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm z-30"
    >
      <div
        id="GuestModelContainer"
        className=" mb-16  md:mt-40 bg-white w-[350px]  shadow-inner border-e-emerald-600 rounded-lg "
      >
        <div className="w-full">
          {/* Close button */}
          <div className="flex items-end justify-end w-fit">
            <button
              onClick={handleCloseModal}
              className="btn rounded-bl-none rounded-tr-none border-white btn-outline ml-[-8px] "
            >
              <CloseIcon />
            </button>
          </div>
          <div className="font-semibold pb-3 text-center text-xl">
            <form onSubmit={handelSubmit} className={"px-2"}>
              <h4 className={"text-black my-3"}>
                <TranslateComponent
                  word={"Please add the required information"}
                />
              </h4>
              <hr className={"my-3"} />
              <div className={"flex flex-col gap-4"}>
                <div className={"flex flex-col gap-4 "}>
                  <input
                    className=" w-full px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black"
                    placeholder={translateText("name", translations)}
                    name={"name"}
                    value={inputs.name}
                    required={true}
                    onChange={handleInputChange}
                  />
                  <input
                    className=" w-full px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black"
                    placeholder={translateText("E-mail", translations)}
                    name={"email"}
                    value={inputs.email}
                    type="email"
                    required={true}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={"flex justify-center"}>
                  <div className={" w-full"}>
                    <div
                      className={
                        "w-full  px-3.5 py-2.5 bg-white rounded-lg border border-gray-300 shadow-sm text-sm text-black flex"
                      }
                    >
                      <input
                        className={"w-full"}
                        placeholder={translateText("phone", translations)}
                        name={"phone"}
                        value={inputs.phone}
                        type="number"
                        required={true}
                        onChange={handleInputChange}
                      />
                      <div
                        className="relative w-[100%]"
                        ref={countryWrapperRef}
                      >
                        <div
                          className="flex gap-2 justify-end text-sm font-medium leading-6  bg-white   text-neutral-900 cursor-pointer"
                          onClick={toggleCountryDropdown}
                        >
                          <Arrow className={"pt-2"} />
                          <span className="font-bold text-black pt-1">
                            {selectedCountry.num_code}
                          </span>

                          <LazyLoadImage
                            loading="lazy"
                            src={
                              selectedCountry ? (
                                selectedCountry.image
                              ) : (
                                <SaudiFlag />
                              )
                            }
                            className="shrink-0 self-stretch aspect-square w-[30px] mask mask-circle object-cover"
                            alt="profile"
                          />
                        </div>
                        {isOpenCountry && (
                          <div className="absolute mt-1 bg-white border rounded-lg shadow-sm max-h-44 z-30 w-full overflow-y-scroll overflow-x-hidden">
                            <input
                              type="text"
                              value={searchQueryCountry}
                              onChange={handleCountrySearch}
                              placeholder={translateText('Search Country...',translations)}
                              className="w-full p-2 border-b focus:outline-none"
                            />
                            {filteredCountries.map((country) => (
                              <div
                                key={country.id}
                                className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCountrySelection(country)}
                              >
                                <LazyLoadImage
                                  src={country.image}
                                  alt={country.name}
                                  className="w-5 aspect-square mr-2 rounded-lg"
                                />
                                <span className="font-bold text-black">
                                  {country.name}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loader ? (
                <div className={"flex justify-center items-center"}>
                  <div className=" mt-5 px-4 py-2 rounded bg-blue-700 dark:bg-blue-700 text-white w-[20%] ">
                    <span className="text-white loading loading-dots loading-lg"></span>
                  </div>
                </div>
              ) : (
                <button
                  type="submit"
                  className="mt-5 px-4 py-2 rounded bg-blue-700 dark:bg-blue-700 text-white "
                >
                  <TranslateComponent word={"Next"} />
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

      {ramadan && ramadan === true ? (
        <>
          {showPopupPay && type !== "gift" && getToken() && (
            <PopupComponent
              ramadan={true}
              ramadanCountry={ramadanCountry}
              subscription={false}
              type={type}
              setShowPopup={setShowPopupPay}
              price={totalPrice}
              projectId={id}
              symbol={getCurrencyLocal() && getCurrencyLocal().name}
              file={file}
            />
          )}
          {showPopupPay && type === "gift" && (
            <PopupForm
              ramadan={true}
              ramadanCountry={ramadanCountry}
              type={type ?? "gift"}
              openPopUp={openPopupGift}
              closePopUp={handleRemovePopUpGift}
              feed={feedGift}
              handleInputChange={handleInputChangeGift}
              handleAddMoreInputs={handleAddMoreInputsGift}
              handleRemoveMoreInputs={handleRemoveMoreInputsGift}
              requestType={true}
              setRequestType={setRequestTypeGift}
              id={id}
              adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
              totalPrice={totalPrice ?? totalPrice}
            />
          )}
        </>
      ) : (
        <>
          {showPopupPay && type !== "gift" && getToken() && (
            <PopupComponent
              subscription={false}
              type={type}
              setShowPopup={setShowPopupPay}
              price={totalPrice}
              projectId={id}
              symbol={getCurrencyLocal() && getCurrencyLocal().name}
              file={file}
            />
          )}
          {showPopupPay && type === "gift" && (
            <PopupForm
              type={type ?? "gift"}
              openPopUp={openPopupGift}
              closePopUp={handleRemovePopUpGift}
              feed={feedGift}
              handleInputChange={handleInputChangeGift}
              handleAddMoreInputs={handleAddMoreInputsGift}
              handleRemoveMoreInputs={handleRemoveMoreInputsGift}
              requestType={true}
              setRequestType={setRequestTypeGift}
              id={id}
              adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
              totalPrice={totalPrice ?? totalPrice}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PopupForLogin;
