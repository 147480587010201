import {createSlice} from "@reduxjs/toolkit";
import axiosClient, {getCurrencyLocal, getLocalLang} from "../axios-client";

const Projects = createSlice({
    name:"StoreProjects",
    initialState:{
        Projects:[],
        loader:false,
        PageTotal:0
    },
    reducers:{
        getProjects:(state,action)=>{
            state.Projects = [...state.Projects, ...action.payload];
        },
        GetForLinkProjects:(state,action)=>{
            state.Projects = [...state.Projects, ...action.payload];
        },
        cleaerProjects:(state,action)=>{
            state.Projects = action.payload;
        },
        ChangeLoaderStatus:(state,action)=>{
            state.loader =action.payload;
        },
        getPageTotal:(state,action)=>{
            state.PageTotal = action.payload;
        }
    }
})

export const {
    getProjects,
    cleaerProjects,
    ChangeLoaderStatus,
    GetForLinkProjects,
    getPageTotal
}=Projects.actions;
export const feacthGetProjects = (activeLink,page=1) => async (dispatch)=>{
    dispatch(ChangeLoaderStatus(true));
    const url = activeLink === 'projects' || activeLink === 'campaigns' ?`/${activeLink}`:`/posts/${activeLink}`;

    axiosClient.get(url, {
        params: {
            limit: 4,
            page: page,
            currency: getCurrencyLocal() && getCurrencyLocal().id
        }
    })
        .then(response => {
            
            dispatch(ChangeLoaderStatus(false));
            dispatch(getPageTotal(response.data.data.pagination.total_pages));
            dispatch(getProjects(response.data.data.data));
        })
        .catch(error => {
            dispatch(ChangeLoaderStatus(false));
            console.error('Error:', error);
        });
}
export const feacthGetForLinkProjects = (activeLink) => async (dispatch)=>{
    dispatch(cleaerProjects([]));
    dispatch(ChangeLoaderStatus(true));
    const url = activeLink === 'projects' || activeLink === 'campaigns' ?`/${activeLink}`:`/posts/${activeLink}`;
    axiosClient.get(url, {
        params: {
            limit: 4,
            page: 1,
            currency:  getCurrencyLocal() && getCurrencyLocal().id
        }
    })
        .then(response => {
            dispatch(ChangeLoaderStatus(false));
            dispatch(GetForLinkProjects(response.data.data.data));
            dispatch(getPageTotal(response.data.data.pagination.total_pages));
        })
        .catch(error => {
            dispatch(ChangeLoaderStatus(false));
            console.error('Error:', error);
        });
}
export const feacthFilter =(price,Country,Categories)=> async (dispatch)=>{
    dispatch(cleaerProjects([]));
    dispatch(ChangeLoaderStatus(true));
    axiosClient.get('projects', {
        params: {
            lang:getLocalLang() && getLocalLang().code,
            limit: 4,
            page: null,
            currency: getCurrencyLocal()&&getCurrencyLocal().id,
            price: price,
            country:Country.id,
            category:Categories
        }
    })
        .then(response => {
            dispatch(ChangeLoaderStatus(false));
            dispatch(getPageTotal(response.data.data.pagination.total_pages));
            dispatch(GetForLinkProjects(response.data.data.data));
        })
        .catch(error => {
            dispatch(ChangeLoaderStatus(false));
            console.error('Error:', error);
        });
}
export default Projects.reducer;