import React, {useEffect, useState} from 'react';
import SelectField from "./FormData/SelectField";
import DonationType from "./FormData/DonationType";
import InputField from "./FormData/InputField";
import PhoneInput from "./FormData/PhoneInput";
import PriceDisplay from "./FormData/PriceDisplay";
import DonateButton from "./FormData/DonateButton";
import TranslateComponent, {translateText} from "../../TranslateComponent";
import FileUploader from "../../FileUploader";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ApiController from "./ApiController";
import {getCurrencyLocal, getToken} from "../../../axios-client";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import PopupForLogin from "../../PopupForLogin";
import PopupCopmponent from "../../PaymentSteps/PopupCopmponent";
import DonationLogic from "./DonationLogic";
import LocalCart from "../../Cart/LocalCart";
function DonationForm({
title,
translations,
countries,
setCountries ,
types,
selectedFileShow,
setSelectedFileShow,
selectedFile,
setSelectedFile,
handleFileChange,
type,
setType,
giftCheck,
setGiftCheck,
checkTextArea,
setCheckTextArea,
checkFile,
setCheckFile,
removeFile,
name,
setName,
email,
setEmail,
selectedCountry,
setSelectedCountry,
selectedType,
setSelectedType,
desc,
setDesc,
handelFetchCountryByType
}) {
    const {createCart} = LocalCart();
    const handelCreateCartLocal = (type=selectedType.id,name=selectedType.name,cart_id=1,price=selectedCountry?.price,symbol='$',image='')=>{
        if(price){
            // console.log('type',selectedType.id);
            // console.log('name',selectedType.name)
            // console.log('cart_id',cart_id)
            // console.log('price',price)
            // console.log('symbol',symbol)
            // console.log('image',image)
            createCart(selectedType.id,selectedType.name,cart_id,selectedCountry.price,symbol,image);
        }else{
            toast.error(translateText('Enter the amount to donate',translations))
        }
    }
    const{
        forPay,openPopup, handleRemovePopUp,requestType,setRequestType,showPopup,setShowPopup,price,handelChangePrice
    } = DonationLogic({type,desc,selectedCountry,selectedFile,translations,setType,});

    return (
        <div className="relative top-0 w-full p-[30px] grow items-start md:max-w-96 text-sm font-medium bg-white rounded-2xl shadow-lg ltr:lg:ml-auto ltr:lg:mr-[unset] rtl:lg:mr-auto rtl:lg:ml-[unset] xl:ml-0 ltr:lg:top-20"
            >

            <h1 className="self-end text-3xl font-bold text-right whitespace-nowrap text-zinc-900">
                <TranslateComponent word={'Donation'}/>
            </h1>

            {types && types.length > 0 && (
                <SelectField
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}

                    handelFetchCountryByType={handelFetchCountryByType}

                    type={'projectType'}
                    label={title}
                    options={types}
                />
            )}

            {countries && countries.length > 0 && (
                <SelectField
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}

                    setCheckTextArea={setCheckTextArea}
                    setCheckFile={setCheckFile}

                    type={'country'}
                    label={translateText("Select country", translations)}
                    options={countries}
                />
            )}

            <DonationType
                type={type}
                setType={setType}
            />
            <hr className='my-5' />
            {checkTextArea ? (
                <>
                    <label className={''}><TranslateComponent word={"ماذا تريد أن تكتب على اللافتة"}/></label>
                    <textarea
                        value={desc}
                        onChange={(e)=>setDesc(e.target.value)}
                        className={'w-full border border-solid shadow-sm border-gray-300 rounded-xl mt-2 p-2'} rows={4}
                        style={{resize: 'none' }}
                        placeholder={translateText("اكتب هنا", translations)}
                    />
                </>
            ):('')}

            {checkFile ? (
                <>
                    <label className={'mt-2'}><TranslateComponent word={"upload a file"}/></label>

                    <div className={'flex flex-row justify-center items-center '}>

                        {(selectedFile && selectedFileShow) && (
                            <div className="w-full col-span-1 lg:col-span-1 mx-2">
                                <div className=" justify-center px-3.5 py-2 mt-3 mx-auto rounded-lg border border-gray-200 border-solid font-bold   shadow-sm hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D]">
                                    <button onClick={removeFile}>{translateText('Remove File', translations)}</button>
                                </div>
                            </div>
                        )}
                        <div className="w-full col-span-1 lg:col-span-1 pt-4">
                            <div
                                className=" justify-center px-3.5 py-2 mt-3 mx-auto rounded-lg border border-gray-200 border-solid font-bold   shadow-sm hover:bg-[#FCD34D]  hover:text-black hover:border-1 hover:border-[#FCD34D]">
                                <FileUploader handleFile={handleFileChange}
                                              text={translateText('Upload File', translations)}/>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-5 lg:gap-0">
                        <div className="col-span-1  lg:col-span-1 pt-4">
                            {selectedFile && selectedFile.type === 'application/pdf' ? (
                                <object
                                    data={selectedFileShow}
                                    type="application/pdf"
                                    width="100%"
                                    height="500px"
                                    aria-label={translateText("Upload a new file", translations)}
                                >
                                    <p>
                                        <TranslateComponent
                                            word={'The file cannot be displayed. Download it to view.'}/>

                                    </p>
                                </object>
                            ) : (
                                <LazyLoadImage
                                    src={selectedFileShow ?? "https://cdn.icon-icons.com/icons2/3001/PNG/512/default_filetype_file_empty_document_icon_187718.png"}
                                    alt={translateText("Image preview", translations)}
                                    className="w-full object-cover w-fit h-[15.5rem] mx-auto rounded-lg shadow-md"
                                />
                            )}
                        </div>
                    </div>
                </>
            ) : ('')}

            <div className="flex gap-1 items-center py-0.5 pl-3 mt-1.5 w-full bg-white min-h-[52px]">
            <div className="flex justify-center items-center self-stretch my-auto min-h-[48px] w-[31px]">
                    <div
                        className="flex overflow-hidden justify-center items-center self-stretch my-auto w-9 rounded-[100px]">
                        <div className="flex justify-center items-center self-stretch p-2 my-auto w-9">
                            <input
                                type="checkbox"
                                name="CheckGift"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setGiftCheck(true);
                                    } else {
                                        setGiftCheck(false);
                                    }
                                }}
                            />

                        </div>
                    </div>
                </div>
                <label htmlFor="dedicateProject"
                       className="my-auto text-base font-medium text-start text-zinc-800 w-[242px]">
                    <TranslateComponent word={'gift this project'}/>
                </label>
                
            </div>
            {giftCheck && (
                <>
                    <InputField
                        label={translateText("gifted name", translations)}
                        placeholder={translateText("name", translations)}
                        name={'name'}
                        value={name}
                    />
                    <InputField
                        label={translateText("Email", translations)}
                        placeholder={translateText("insert email", translations)}
                        type="email"
                        name={'email'}
                        value={email}

                    />
                    <label className='pt-1 mt-1.5 text-base font-medium leading-none text-slate-700'><TranslateComponent word={"Enter phone number"} /></label>
                    <PhoneInput/>

                </>
            )}
            <div
                className="flex gap-4 items-center mt-6 w-full text-sm font-medium leading-6 text-right min-h-[58px] text-zinc-600">
                <p className="flex-1 shrink self-stretch my-auto w-full min-h-[48px] min-w-[240px]">
                    <TranslateComponent word={'سيتم طباعة ما كتبته في المربع اعلاه على لافتة وسيتم ارسالها اليك'}/> *
                </p>
            </div>
            <PriceDisplay price={selectedCountry?.price} currency={selectedCountry?.code}/>
            <DonateButton onClick={forPay} addCart={handelCreateCartLocal} price={selectedCountry?.price} currency={selectedCountry?.symbol}/>
            {!getToken() && (
                <PopupForLogin
                    type={'subProject'}
                    openPopUp={openPopup}
                    closePopUp={handleRemovePopUp}
                    requestType={requestType}
                    setRequestType={setRequestType}
                    id={selectedType?.id}
                    adahy_price_id={getCurrencyLocal() && getCurrencyLocal().id}
                    totalPrice={price}
                    file={selectedFile}

                />
            )}

            {showPopup && getToken() && (
                <PopupCopmponent
                    file={selectedFile}
                    type={type}
                    setShowPopup={setShowPopup}
                    projectId={selectedType?.id} price={price}
                    symbol={'project.symbol'}
                    handelChangePrice={handelChangePrice}
                />
            )}

        </div>
    );
}

export default DonationForm;